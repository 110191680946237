



































































































































import { Droit } from '@/api/models/authorization/droits/droit';
import { Fonction } from '@/api/models/authorization/fonctions/fonction';
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { PaginatedList } from '@/api/models/common/paginatedList';
import { successAlert, errorAlert } from '@/libs/sweetAlerts/alerts'
import { BButton, BCard, BCardHeader, BCardTitle, BForm, BFormCheckbox, BFormGroup, BFormInput, BFormSelect, BModal, BSpinner, BTable, VBTooltip } from 'bootstrap-vue';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import { RightLevelFlag } from '@/api/models/enums/rightLevelFlag';
import appSettings from "@/appSettings";
import { EnumTenants } from "@/api/models/enums/enumTenants";

@Component({
  components: {
    BButton, 
    BModal, 
    BForm, 
    BFormInput,
    BFormGroup,
    BSpinner,
    ValidationProvider,
    ValidationObserver,
    BFormSelect,
    BTable,
    BCard,
    BCardHeader,
    BCardTitle,
    BFormCheckbox,
  },
  directives: {
    'b-tooltip': VBTooltip,
  }
})
export default class DroitFonctions extends Vue {
  @Prop({required: true}) listDroits!: PaginatedList<Droit>
	@Prop({required: true}) fonctionSelected!: Fonction

  tenantId = appSettings.getTenantId();
  isTenantRessif = appSettings.getIsTenantRESSIF();
  tenants = EnumTenants;

  loading = true;
  rightLevelFlag = RightLevelFlag;
  displayedFonction : any = null;

  refreshIndex: any = 0;
  droits: any = []

  fields = [
    { 
      key: 'libelle',
      label: 'Libellé'
    },
    { 
      key: 'read',
      label: 'Lire'
    },
    { 
      key: 'update',
      label: 'Modifier'
    },
    { 
      key: 'create',
      label: 'Créer'
    },
    {
      key: 'delete',
      label: 'Supprimer'
    },
    {
      key: 'admin',
      label: 'Administration'
    }
  ]

	created() {
		this.reload()
	}

  @Watch("fonctionSelected")
  reload() {
    this.displayedFonction = { ...this.fonctionSelected, droits : {}}
    this
      .fonctionSelected
      .droits
      .forEach(
        (droit: any) => this.displayedFonction.droits[droit.code] = droit.flag
			)
  }

  resetForm(){
    this.refreshIndex++ 
    this.droits = []
  }

  async submit(fonction: any){
    var data: any = {
      id: fonction.id,
      droits: this.droits
    }
    await this.$http.myressif.fonctions
      .updateDroits(fonction.id, data)
      .then(
        async (response: string) => {
          successAlert.fire({
            title: "Mise à jour des droits",
            text: "Droits mis à jour avec succès",
          });
        },
        (error: any) => {
          if (
            error?.response?.data?.errors &&
            Object.keys(error.response.data.errors).length
          ) {
            errorAlert.fire({
              text: error.response.data.errors[
                Object.keys(error.response.data.errors)[0]
              ],
            });
          } else {
            errorAlert.fire({
              text: error?.response?.data?.detail ?? error.message,
            });
          }
        }
      ).finally(() => {
        this.reload()
        this.$emit('load-fonctions')
      });
      
  }

	async change(fonction: any, droit:any, checkOrNo: any, column : any) {
    if (checkOrNo) {
      var index = 0;
        this.droits.forEach((x: any) => {
          if (x.code == droit.code){
            index++;
          }
        });

        if (index == 0){
          this.droits.push({code: droit.code, flag: fonction.droits[droit.code] ? fonction.droits[droit.code] + column : column})
        }
        else {
          this.droits.forEach((x: any) => {
            if (x.code == droit.code){
              x.flag += column
            }
          });
        }
    }
    else {
      var index = 0;
        this.droits.forEach((x: any) => {
          if (x.code == droit.code){
            index++;
          }
        });

        if (index == 0){
          this.droits.push({code: droit.code, flag: fonction.droits[droit.code] - column})
        }
        else {
          this.droits.forEach((x: any) => {
            if (x.code == droit.code){
              x.flag -= column
            }
          });
        }
    }
	}
}
