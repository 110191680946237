








































































import {
  BButton, BModal, BForm, BFormInput, BFormGroup, BSpinner, BFormTextarea, BFormCheckbox
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Fonction } from '@/api/models/authorization/fonctions/fonction';
import { required, min, max } from '@validations'

@Component({
  components: {
    BButton, 
    BModal, 
    BForm, 
    BFormInput,
    BFormTextarea,
    BFormGroup,
    BSpinner,
    ValidationProvider,
    ValidationObserver,
    BFormCheckbox
  },
})

export default class UpdateFonctionModal extends Vue {
  @Prop({ required: false, default: "Mise à jour d'une fonction" }) title!: string
  @Prop({required: true}) fonction!: Fonction
  @Prop({ required: true }) loading!: boolean
  @Prop({ required: true }) allowRang!: boolean

  required = required;
  min = min;
  max = max;

  async updateFonction(event: any) {
    event.preventDefault();
    (this.$refs.formValidation as InstanceType<typeof ValidationObserver>)
      .validate()
      .then((isSuccess) => {
        if (isSuccess) {
          this.$emit('update-fonction', this.fonction);
        }
      })
  }
}
