




















































































































































































































































import AddFonctionModal from './AddFonctionModal.vue';
import UpdateFonctionModal from './UpdateFonctionModal.vue';
import { Fonction } from '@/api/models/authorization/fonctions/fonction';
import { Droit } from '@/api/models/authorization/droits/droit';
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { PaginatedList } from '@/api/models/common/paginatedList';
import { VueAutosuggest }  from 'vue-autosuggest';
import { successAlert, errorAlert } from '@/libs/sweetAlerts/alerts'
import { DetailsUtilisateur } from '@/api/models/utilisateurs/utilisateur';
import { BTable, BRow, BCol, BPagination, BAlert } from 'bootstrap-vue';


@Component({
  components: {
		BAlert,
		BTable,
		BRow,
		BCol,
		BPagination,
		VueAutosuggest,
		AddFonctionModal,
		UpdateFonctionModal
  },
})
export default class Fonctions extends Vue {
  @Prop() listFonctions?: PaginatedList<Fonction>

  fonctionSelected: any | null= {
		libelle: ""
  };

	allowRang = false;
  mainLoading: boolean = false;
  modalLoading: boolean = false;
  selectedDroit: Droit | null = null;
  fonctionSelectedUpdate: Fonction | null = null;
  tableColumns = [
    { key: 'nom', sortable: true },
    { key: 'prenom', sortable: true }
  ]
  paginatedListUtilisateurs = new PaginatedList<DetailsUtilisateur>();

  isSortDirDesc = false
  sortBy='nom'

  perPage = 10
  currentPage = 1
  perPageOptions = [2, 5, 10, 25, 50, 100];


	async created() {
    await this.$http.myressif.parametres
      .getByNom("fonctions_allowed_view_rank")
      .then((res: any) => {
        this.allowRang = JSON.parse(res.valeur).some((x: string) => this.$store.state.user.fonctions_codes.includes(x))
      });
	}


	get paginationFrom() {
    return (
      this.perPage * (this.currentPage - 1) +
      (this.paginatedListUtilisateurs.items.length ? 1 : 0)
    );
  }

  get paginationTo() {
    return (
      this.perPage * (this.currentPage - 1) +
      this.paginatedListUtilisateurs.items.length
    );
  }

	onFonctionSelected(value :any) {
		this.fonctionSelected = value
			this.$emit('on-fonction-selected', value)
	}

	async createFonction(newFonction: Fonction) {
		this.modalLoading = true;
		await this.$http.myressif.fonctions.post(newFonction)
			.then(async (response : string) => {
				this.$bvModal.hide('modal-add-fonction')
				successAlert.fire({
					title: 'Création d\'une fonction',
					text: 'Ajout effectué avec succès'
				})
				this.$emit('load-fonctions');
				(this.$refs['addFonctionModal'] as any).newFonction.libelle = "";
			}, (error: any) => {
				if (
					error?.response?.data?.errors &&
					Object.keys(error.response.data.errors).length
				) {
					errorAlert.fire({
						text: error.response.data.errors[
							Object.keys(error.response.data.errors)[0]
						],
					});
				} else {
					errorAlert.fire({
						text: error?.response?.data?.detail ?? error.message,
					});
				}
			})
			this.modalLoading = false;
	}



@Watch("currentPage")
  @Watch("perPage")
  async paginationChanged() {
    await this.loadListUtilisateurs({
      pageNumber: this.currentPage,
      pageSize: this.perPage,
	  fonctionCode: this.fonctionSelected.code
    });
  }

	async loadListUtilisateurs(params: any) {
		if (!params || params?.reset) {
		this.paginatedListUtilisateurs = new PaginatedList<DetailsUtilisateur>()
		}

		await this.$http.myressif.utilisateurs
		.paginatedList(
			params?.pageNumber ?? 1,
			params?.pageSize ?? 10,
			params?.search ?? "",
			false,
			this.sortBy,
			this.isSortDirDesc,
			params?.fonctionCode ?? ""
		)
		.then((response: PaginatedList<DetailsUtilisateur>) => {
			this.paginatedListUtilisateurs = response;
		});
  	}

	async updateFonction(fonctionToUpdate: Fonction) {
		this.modalLoading = true;
		if(!this.fonctionSelectedUpdate)
		return;

		await this.$http.myressif.fonctions.put(fonctionToUpdate.id, fonctionToUpdate)
		.then(async(response: any) => {
			this.$bvModal.hide('modal-update-fonction');
			successAlert.fire({
			title: 'Modification d\'une fonction',
			text: 'Modification effectuée avec succès',
			})
					this.$emit('load-fonctions')
		}, 
				(error: any) => {
					if (
						error?.response?.data?.errors &&
						Object.keys(error.response.data.errors).length
					) {
						errorAlert.fire({
							text: error.response.data.errors[
								Object.keys(error.response.data.errors)[0]
							],
						});
					} else {
						errorAlert.fire({
							text: error?.response?.data?.detail ?? error.message,
						});
					}
				});

		this.modalLoading = false;
	}

	askRemoveFonction(fonction: Fonction) {
    this.$bvModal
      .msgBoxConfirm(
        "Etes-vous sûr de vouloir supprimer la fonction "+ fonction.libelle +" ?",
        {
          title: "Confirmation de suppression",
          size: "sm",
          okVariant: "primary",
          okTitle: "Oui",
          cancelTitle: "Non",
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
          centered: true,
        }
      )
      .then(async (value) => {
        if (value === true) {
          await this.removeFonction(fonction);
        } else {
          return;
        }
      });
  }

	async removeFonction(fonction: Fonction) {
		await this.$http.myressif.fonctions.delete(fonction.id)
			.then(async (response: any) => {
				successAlert.fire({
					title: 'Suppression d\'une fonction',
					text: 'Suppression effectuée avec succès'
						})
				this.$emit('load-fonctions');
			}, (error: any) => {
				errorAlert.fire({
					title: 'Oops',
					text: error.message
				})
			})	  
	}

	clickUpdateFonction(fonction: Fonction) {
		this.fonctionSelectedUpdate = fonction
    this.$bvModal.show('modal-update-fonction');
	}
}
