



























import DroitFonctions from './droits/DroitFonctions.vue';
import Fonctions from './fonctions/Fonctions.vue';
import { Component, Vue } from 'vue-property-decorator';
import { PaginatedList } from '@/api/models/common/paginatedList';
import { Droit } from '@/api/models/authorization/droits/droit';
import { Fonction } from '@/api/models/authorization/fonctions/fonction';
import appSettings from "@/appSettings";

@Component({
	components: {
		DroitFonctions,
		Fonctions
	}
})
export default class Authorization extends Vue {
  listDroits: PaginatedList<Droit> = new PaginatedList<Droit>()
	listFonctions: PaginatedList<Fonction> = new PaginatedList<Fonction>()
  mainLoading: boolean = false
	fonctionSelected: any | null = null

	tenantId = appSettings.getTenantId();

	created() {
		this.loadDroits()
		this.loadFonctions()
	}

	reloadFonctions() {
		this.loadFonctions()
	}

	reloadDroits() {
		this.loadDroits()
	}

	async loadFonctions() {
	  await this.$http.myressif.fonctions.paginatedList(1, 100, true)
	  	.then(
			  (response: PaginatedList<Fonction>) => {
					this.fonctionSelected = null
				  this.listFonctions = response
			  }
		  )
  }

	async loadDroits() {
	  await this.$http.myressif.droits.paginatedList(1, 100)
	  	.then(
			  (response: PaginatedList<Droit>) => {
				  this.listDroits = response;
				  this.listDroits.items = this.listDroits.items.filter(x => x.tenantId == null || x.tenantId == this.tenantId)
			  }
		  )
  }

	onFonctionSelected(newFonctionSelected: any) {
		this.fonctionSelected = newFonctionSelected

    setTimeout(() => {
      let formCard = (this.$refs['droitsFonctionsCard'] as any);
      formCard.scrollIntoView({ behavior: 'smooth' })
    }, 350)
	}
}
